<template>
  <div
    class="container p0"
    v-title
    :data-title="$t('i18n.dataOverview')"
    id="dataOverview"
  >
    <iframe :src="src"></iframe>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
export default {
  name: "Dashboard",
  setup(props, { emit }) {
    emit("public_header", false);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      src: proxy.$config.url.dashboard2,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
#dataOverview {
  margin: 0 !important;
  height: 100%;
  max-width: 100%;
  iframe {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
  }
}
</style>
